@import 'includes/_flexgrid.css';
@import "includes/_rap_theme.css";

.overlay-box-container {
    margin-top: 10px;
    /*overflow: hidden;*/
    /*max-height: 315px;*/
}

.overlay-box-container > div {
    margin-bottom: 10px;
}

.overlay-box {
    max-height: 315px;
    color: #111;
    position: relative;
    overflow: hidden;
    opacity: 1;
}

.overlay-box-link-heading {
    transition: all 0.4s ease-out;
    background: rgba(0, 0 , 0, 0.8);
    border: 2px solid white;
    bottom: 0;
    color: #fff;
    min-width: 70%;
    padding: 25px 30px 25px 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.overlay-box:hover .overlay-box-link-heading {
    background: rgba(0, 0 , 0, 1);
    min-width: 110%;
}

.overlay-box-container h4{
    text-align: center;
}
.overlay-box-container h3{
    text-align: center;
}
